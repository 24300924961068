import api from "../services/api";
import { sortAlphabetically, sortByCategory, sortBySearch } from "../common/helpers.js";

const state = {
  commands: [{
    cog: "General",
    name: "/help",
    short_desc: "Spawns help embed with all commands and tutorials.",
    long_desc: "Spawns help embed with all commands and tutorials."
  }, {
    cog: "General",
    name: "/manage",
    short_desc: "Manage all monitoring settings in current guild.",
    long_desc: "Manage all monitoring settings in current guild."
  }, {
    cog: "Status",
    name: "/monitor",
    short_desc: "Monitor Discord bots, websites and games.",
    long_desc: "Monitor Discord bots, websites and games."
  }, {
    cog: "Status",
    name: "/incident",
    short_desc: "Want to create custom Discord incidents.",
    long_desc: "Want to create custom Discord incidents."
  }, {
    cog: "Status",
    name: "/analytics",
    short_desc: "Check guild, bot, users statistics and activity.",
    long_desc: "Check guild, bot, users statistics and activity."
  }, {
    cog: "Status",
    name: "/statusrole",
    short_desc: "Reward users with role by having text in status.",
    long_desc: "Reward users with role by having text in status."
  }],
  categories: ["All", "Status", "General"],
  search: "",
  category: "all",
};

const getters = {
  commands: (state) => {
    const sortedAlphabetically = sortAlphabetically(state.commands);
    return sortByCategory(sortBySearch(sortedAlphabetically, state.search), state.category);
  },
  categories: (state) => {
    const categories = [...state.categories];
    state.commands.forEach((command) => {
      if (command.cog !== null) {
        categories.push(command.cog);
      }
    });
    categories.sort((a, b) => {
      return a.localeCompare(b);
    });
    // remove all duplicates
    return [...new Set(categories)];
  },
  search: (state) => {
    return state.search;
  },
  category: (state) => {
    return state.category;
  },
};

const mutations = {
  SET_COMMANDS: (state, payload) => {
    state.commands = payload;
  },
  SET_SEARCH: (state, payload) => {
    state.search = payload;
  },
  SET_CATEGORY: (state, payload) => {
    state.category = payload;
  },
};

const actions = {
  async GET_COMMANDS({ commit }) {
    commit("SET_STATE");
    await api
      .get("/commands")
      .then(({ data }) => {
        if (!Array.isArray(data)) {
          throw Error("Expected array of objects.");
        } else {
          commit("SET_COMMANDS", data);
        }
      })
      .catch(() => {
        commit("SET_ERROR", false);
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
