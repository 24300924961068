<template>
  <div>
    <base-section class="is-medium">
      <div class="is-stretch">
        <h1 class="title is-3 is-size-4-mobile has-text-weight-bold is-spaced">
          Advanced Discord Monitoring
        </h1>
        <h2 class="subtitle is-6">
          Status Bot has many features for Monitoring and allows you to view overall status, create powerfull incidents for info to your members, stay up to date with the latest news and discord status!
        </h2>
        <div class="buttons mt-6">
          <home-button :link="links.invite" action="Get Started" class="is-primary" />
          <home-button href="https://discord.gg/4rphpersCa" action="Need Help?" class="is-white is-outlined shine" />
        </div>
      </div>
    </base-section>

    <base-section class="is-medium is-charlie">
      <div class="is-stretch">
        <h1 class="title is-4 has-text-weight-bold is-spaced">What exactly does Status Bot offer?</h1>
        <h2 class="subtitle is-6">Here's why you should start using Status Bot today.</h2>
        <div class="columns is-multiline">
          <div
            v-for="feature in features"
            :key="feature.id"
            class="column is-6-desktop is-6-tablet is-12-mobile"
          >
            <item-card :item="feature" iconColor="bg-primary" />
          </div>
        </div>
      </div>
    </base-section>

    <base-section class="is-medium">
      <div class="is-stretch">
        <h1 class="title is-4 has-text-weight-bold is-spaced">Get more with Premium!</h1>
        <h2 class="subtitle is-6 is-size-6-mobile">
          Improve your experience by subscribing to one of our Premium plans. By purchasing a Premium plan you are supporting Status Bot's development while unlocking extra features.
        </h2>
        <home-button path="/premium" action="Get Premium" class="is-warning is-outlined shine" />
      </div>
    </base-section>

    <base-section class="is-medium is-charlie">
      <div class="is-stretch">
        <h1 class="title is-4 has-text-weight-bold is-spaced">Looking for support?</h1>
        <h2 class="subtitle is-6 is-size-6-mobile">
          If you got any questions or issues with Status Bot don't hesitate to join the official
          Discord server, we'll help you there in a split of second.
        </h2>
        <home-button :link="links.discord" action="Join Server" class="is-success is-outlined shine" />
      </div>
    </base-section>

    <base-section class="is-medium">
      <div class="is-stretch">
        <h1 class="title is-4 has-text-weight-bold is-spaced">Leave a review?</h1>
        <h2 class="subtitle is-6 is-size-6-mobile">
          If you had good experience and you would like to review some of your impressions, well you're at right place.
        </h2>
        <home-button :link="links.topgg" action="Review Us" class="is-info is-outlined shine" />
      </div>
    </base-section>

    <!-- <base-section class="is-medium" id="users">
      <div class="is-stretch">
        <h1 class="title is-4 has-text-weight-bold is-spaced mb-6">Some User Reviews</h1>
      </div>
      <div class="is-stretch2">
        <images />
      </div>
    </base-section> -->
  </div>
</template>

<script>
import features from "@/assets/json/features.json";
import BaseSection from "@/components/BaseSection";
import HomeButton from "@/components/home/HomeButton";
import ItemCard from "@/components/ItemCard";

export default {
  components: {
    BaseSection,
    HomeButton,
    ItemCard,
  },

  data: () => {
    return {
      links: {
        invite:
          "https://discord.com/api/oauth2/authorize?client_id=847180236545327164&permissions=268913664&scope=bot%20applications.commands",
        discord: "https://discord.com/invite/4rphpersCa",
        topgg: "https://top.gg/bot/847180236545327164",
      },
      features,
    };
  },
};
</script>

<style scoped lang="scss">
.is-stretch {
  max-width: 800px !important;
  margin: 0 auto !important;
}
.is-stretch2 {
  max-width: 1500px !important;
  margin: 0 auto !important;
}
</style>
