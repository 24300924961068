import axios from "axios";

const state = {
    "data": {
        "guild_count": Number,
        "user_count": Number,
        "monitor_count": Number,
        "uptime": Object,
        "latency": Object,
        "lines": Number,
        "node": String,
        "djs": String,
        "ram": Object,
        "cpu": Object,
        "os": String
    }
};

const getters = {
  data: (state) => {
    return state.data;
  }
};

const mutations = {
  SET_STATS: (state, payload) => {
    state.data = payload.data;
  },
};

const actions = {
  async GET_STATS({ commit }) {

    commit("SET_STATE");
    await axios({
      method: "get",
      url: "https://api.statusbot.us/status",
      headers: {
        Authorization: "gqG5ktkFq4VCQQTwlU2n52Vco7PGLJ1939lWQmFB0mV30pW4X2V0XK5bmk4uF6oX",
      },
    }).then(({ data }) => {
      console.log(data)
        if (typeof data !== "object" || data === null) {
          throw Error("Expected object of objects.");
        } else {
          commit("SET_STATS", data);
        }
      })
      .catch(() => {
        commit("SET_ERROR", true);
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
