import axios from "axios";

const api = axios.create({
    baseURL: "https://api.statusbot.us",
    headers: {
      "Authorization": "gqG5ktkFq4VCQQTwlU2n52Vco7PGLJ1939lWQmFB0mV30pW4X2V0XK5bmk4uF6oX",
      "Content-Type": "application/json",
    },
});

export default api;