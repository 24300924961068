import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import NotFound from "../views/errors/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    alias: '/home',
    meta: {
      title: "Status Bot - The best Monitor Bot for Discord",
    },
  },
  { 
    path: '/invite', 
    beforeEnter: () => {
        window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=847180236545327164&permissions=275683724288&scope=bot%20applications.commands';
    }
  },
  { 
    path: '/server', 
    beforeEnter: () => {
      window.location.href = 'https://discord.gg/4rphpersCa';
    }
  },
  { 
    path: '/free-premium',
    beforeEnter: () => {
      window.location.href = 'https://cdn.crni.xyz/r/ok.mp4';
    }
  },
  { 
    path: '/topgg', 
    beforeEnter: () => {
      window.location.href = 'https://top.gg/bot/847180236545327164';
    }
  },
  {
    path: "/commands",
    name: "Commands",
    component: () => import(/* webpackChunkName: "commands" */ "../views/Commands.vue"),
    meta: {
      title: "Commands - Status Bot",
    },
  },
  //{
  //  path: "/status",
  //  name: "Status",
  //  component: () => import(/* webpackChunkName: "status" */ "../views/Status.vue"),
  //  meta: {
  //    title: "Status - Status Bot",
  //  },
  //},
  {
    path: "/permissions",
    name: "Permissions",
    component: () => import(/* webpackChunkName: "permissions" */ "../views/Permissions.vue"),
    meta: {
      title: "Permissions - Status Bot",
    },
  },
  {
    path: "/premium",
    name: "Premium",
    component: () => import(/* webpackChunkName: "premium" */ "../views/Premium.vue"),
    meta: {
      title: "Premium - Status Bot",
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import(/* webpackChunkName: "privacy" */ "../views/legal/Privacy.vue"),
    meta: {
      title: "Privacy Policy - Status Bot",
    },
  },
  {
    path: "/policy",
    name: "Policy",
    component: () => import(/* webpackChunkName: "policy" */ "../views/legal/Privacy.vue"),
    meta: {
      title: "Privacy Policy - Status Bot",
    },
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import(/* webpackChunkName: "terms" */ "../views/legal/Terms.vue"),
    meta: {
      title: "Terms of Service - Status Bot",
    },
  },
  {
    path: '/:pathMatch(.*)*',
    alias: '/pathMatch(.*)*',
    name: "Not Found",
    component: NotFound,
    meta: {
      title: "Page Not Found - Status Bot",
    },
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
