<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <slot />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BaseSection",
};
</script>
